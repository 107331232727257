<template>
  <a-layout-content>
    <a-table
      :columns="columns"
      :rowKey="record => record.Id"
      :dataSource="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
    <span slot="item" slot-scope="item">
      {{ item.Parent ? item.Parent.Name : '-' }}
    </span>
    <span slot="action" v-if="role === 'admin'" slot-scope="item">
        <a-button type="primary">
          <router-link :to="{ name: 'categories-edit', params: { categoryId: item.Id } }">
            Редактировать
          </router-link>
        </a-button>
    </span>
    </a-table>
  </a-layout-content>
</template>
<script>
import { sortQuery } from '../../helpers';

export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      page: 1,
      columns: [
        {
          title: 'Наименование Категории',
          dataIndex: 'Name',
          scopedSlots: { customRender: 'Name' },
          sorter: true,
        },
        {
          title: 'Родительская категория',
          scopedSlots: { customRender: 'item' },
        },
        {
          title: 'Действие',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    role() {
      const { Role } = this.$store.getters['user/user'];
      return Role;
    },
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.page = pagination.current;
      this.pagination = {
        ...this.pagination,
        current: pagination.current,
      };
      console.log(sortQuery(sorter));
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sort: sortQuery(sorter),
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;

      this.$api.getCategories({ page: this.page, ...params }, ({ data }) => {
        this.loading = false;
        this.data = data.data;
        this.pagination = {
          pageSize: data.meta.per_page,
          current: data.meta.current_page,
          total: data.meta.total,
        };
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
